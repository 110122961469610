<template>
  <div class="beauty-ai-appointment-unit-analysis-detail">
    <PageTitle
      title="查看服務人員績效"
      hideBtn
      icon="chevron_left"
      @iconClick="$router.push({name: 'OrgBeautyAIAppointmentUnitAnalysis'})"
    />
    <BeautyAIAppointmentUnitInfo v-loading="loading" :data="displayData" />
    <BeautyAIAppointmentUnitAnalysisDetailAnnualChart v-loading="loading" />
    <BeautyAIAppointmentUnit360Analysis
      v-loading="loading"
      :data="metricsData"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import BeautyAIAppointmentUnitInfo from './components/BeautyAIAppointmentUnitInfo.vue'
import BeautyAIAppointmentUnitAnalysisDetailAnnualChart from './components/BeautyAIAppointmentUnitAnalysisDetailAnnualChart.vue'
import BeautyAIAppointmentUnit360Analysis from './components/BeautyAIAppointmentUnit360Analysis.vue'
import { FindOrgBeautyAIAptUnitAnalysis } from '@/api/beautyAI'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { useRoute } from 'vue-router/composables'
import { get } from 'lodash'
import { imgSrc } from '@/utils/helper'
import dayjs from '@/lib/dayjs'
import { formatDate } from '@/utils/date'
import PageTitle from '@/components/Title/PageTitle.vue'
import { useOrg } from '@/use/useOrg'

export default defineComponent({
  name: 'OrgBeautyAIAppointmentUnitAnalysisDetail',
  components: {
    PageTitle,
    BeautyAIAppointmentUnitInfo,
    BeautyAIAppointmentUnitAnalysisDetailAnnualChart,
    BeautyAIAppointmentUnit360Analysis,
  },
  setup (props) {
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()
    const { orgId } = useOrg()
    const route = useRoute()
    const aptUnitId = computed(() => route.params.id)
    const data = ref({})
    const loading = ref(false)

    const serviceYears = (createdAt) => {
      const now = dayjs()
      const start = dayjs(createdAt)
      const diff = now.diff(start, 'days')
      const years = Math.floor(diff / 365)
      const days = diff % 365
      return years ? `${years} 年 ${days} 天` : `${days} 天`
    }

    const aptUnitData = computed(() => get(data.value, 'appointmentUnit'))
    const metricsData = computed(() => {
      return {
        ...get(data.value, 'metrics', {}),
        totalStar: get(data.value, 'totalStar'),
      }
    })

    const displayData = computed(() => {
      return {
        name: get(aptUnitData.value, 'name'),
        avatar: imgSrc(500, get(aptUnitData.value, 'Image') || null),
        shop: get(aptUnitData.value, 'Shop.name'),
        createdAt: formatDate(get(aptUnitData.value, 'createdAt'), 'YYYY/MM/DD'),
        serviceYears: serviceYears(get(aptUnitData.value, 'createdAt')),
        ...metricsData.value,
        ...aptUnitData.value,
      }
    })

    onBeforeMount(async () => {
      await simpleFetch(FindOrgBeautyAIAptUnitAnalysis, {
        orgId: orgId.value,
        id: aptUnitId.value,
      }, (res) => {
        data.value = res
      })
      loading.value = false
    })
    return { data, aptUnitData, displayData, loading, metricsData }
  },
})
</script>

<style lang="postcss" scoped>
.beauty-ai-appointment-unit-analysis-detail {
  @apply flex flex-col gap-[30px];
}
</style>

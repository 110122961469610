<template>
  <div class="beauty-ai-appointment-unit-info">
    <div class="flex items-center" style="gap: 24px">
      <!-- left -->
      <div class=" flex-shrink-0">
        <img class="appointment-unit-avatar" :src="data.avatar" alt="apy-unit-avatar">
      </div>

      <!-- right -->
      <div class="w-full">
        <!-- top -->
        <div>
          <p class="font-medium" style="margin-bottom: 8px">{{ data.name || '-' }}</p>
          <div class="flex items-center" style="gap: 28px">
            <p v-for="item in displayUpperInfo" :key="item.label" class="text-sm text-gray-80">
              {{ item.label }}：{{ item.value }}
            </p>
          </div>
        </div>

        <div class="divider" />

        <!-- bottom -->
        <div class="flex justify-between items-center">
          <div v-for="item in displayLowerInfo" :key="item.label">
            <div class="flex items-center" style="gap: 4px">
              <p class="text-sm text-gray-80">{{ item.label }}</p>
              <TipInfo v-if="item.tooltip" :width="180" :size="16" placement="top">{{ item.tooltip }}</TipInfo>
            </div>
            <div class="flex items-baseline" style="gap: 4px">
              <p class="font-medium">{{ item.value }}</p>
              <p class="font-medium text-sm">{{ item.unit }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex" style="gap: 8px">
      <Tag v-for="service in displayServices || []" :key="service.name" size="small" type="action">
        {{ service.name }}
      </Tag>
      <div class="cursor-pointer select-none" @click="expandServices = !expandServices">
        <Tag
          size="small"
          type="info"
          class="tag__more"
          style="background: white"
        >
          <div class="flex items-center">
            <span v-if="!expandServices">更多</span>
            <span v-if="expandServices">更少</span>
            <span v-if="expandServices" class="material-icons text-sub rotate-180">
              expand_more
            </span>
            <span v-if="!expandServices" class="material-icons text-sub rotate-180">
              expand_less
            </span>
          </div>
        </Tag>
      </div>
    </div>
  </div>
</template>

<script>
import TipInfo from '@/components/TipInfo.vue'
import { defineComponent, computed, ref } from 'vue'
import { get } from 'lodash'

export default defineComponent({
  name: 'BeautyAIAppointmentUnitInfo',
  components: { TipInfo },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const expandServices = ref(false)
    const displayServices = computed(() => {
      return expandServices.value ? get(props.data, 'AppointmentServices') : get(props.data, 'AppointmentServices', []).slice(0, 4)
    })
    const displayUpperInfo = computed(() => {
      return [
        { label: '到職日期', value: get(props.data, 'createdAt') || '-' },
        { label: '服務年資', value: get(props.data, 'serviceYears') || '-' },
        { label: '隸屬分店', value: get(props.data, 'shop') || '-' },
      ]
    })
    const displayLowerInfo = computed(() => {
      return [
        { label: '平均客單價', value: toDisplayValue(get(props.data, 'customerStandardValue')), unit: '元', tooltip: '過去 365 天平均訂單服務價格' },
        { label: '平均服務時數', value: toDisplayValue(get(props.data, 'serviceTimeValue')), unit: '小時', tooltip: '過去 365 天平均訂單的服務時數' },
        { label: '平均月服務時數', value: toDisplayValue(get(props.data, 'monthlyAvgTotalBookingTime')), unit: '小時', tooltip: '過去 365 天平均一個月的服務時數' },
        { label: '平均月服務次數', value: toDisplayValue(get(props.data, 'orderTakingValue')), unit: '次', tooltip: '過去 365 天平均一個月的服務數量' },
        { label: '平均月營業額', value: toDisplayValue(get(props.data, 'contributionValue')), unit: '元', tooltip: '過去 365 天平均一個月的服務營業額' },
      ]
    })

    const toDisplayValue = (value, unit = '') => {
      if (value !== 0 && !value) return '-'
      return Math.round(value).toFixed(2).toLocaleString()
    }
    return { displayUpperInfo, displayLowerInfo, displayServices, expandServices }
  },
})
</script>

<style lang="postcss" scoped>
.beauty-ai-appointment-unit-info {
  @apply p-[15px] bg-white rounded-[8px];
  @apply flex flex-col gap-[15px];
}

.divider {
  @apply w-full h-[2px] bg-[#ECECEC] my-[12px];
}

.appointment-unit-avatar {
  @apply w-[135px] h-[135px] rounded-[12px] overflow-hidden;
}
</style>
